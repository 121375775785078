import { CompanySubtype } from "~/types/customers";
import {
  DateRangesList,
  FilterOption,
  Filters,
  FilterType,
} from "~/types/filters";

/**
 * Set filters array from route query parameters
 * @param filters - The object containing all filter settings.
 */
export const useNewGetFiltersQS = (filters: Filters) => {
  const route = useRoute();

  const filtersParamsEntries = Object.entries(route.query).filter(([key]) =>
    key.startsWith("filter")
  );

  // Get filter 'key' from 'filter[key][]' or 'filter[key]' no regexp
  function getFilterKey(key: string) {
    return key.split("[")[1].split("]")[0];
  }

  // Get filter 'itemKey' from 'filter[key][itemKey]' no regexp
  function getNewDateRangeItemKey(key: string) {
    return key.split("[")[2].split("]")[0];
  }

  let dateRangeFilters: DateRangesList = {};

  // Assigning 'chosenOptions'
  filtersParamsEntries.forEach(([queryKey, value]) => {
    const filterQueryKey = getFilterKey(queryKey);
    const rangeFrom = filterQueryKey.includes("_from");
    const rangeTo = filterQueryKey.includes("_to");
    const filterKey = rangeFrom
      ? filterQueryKey.replace("_from", "")
      : rangeTo
        ? filterQueryKey.replace("_to", "")
        : filterQueryKey;
    const filter = filters[filterKey];

    if (!filters || !filter) return;

    // Price logic
    if (filter.type === FilterType.PRICE_RANGE) {
      if (rangeFrom) {
        filter.chosenOptions[0].value = +value;
      } else if (rangeTo) {
        filter.chosenOptions[1].value = +value;
      }

      return;
    }

    // Date range types
    if ([FilterType.DATE_RANGE, FilterType.MAIN_DATE].includes(filter.type)) {
      if (rangeFrom) {
        dateRangeFilters[filterKey] = {
          from: typeof value === "string" ? new Date(value) : null,
          to:
            dateRangeFilters[filterKey] && dateRangeFilters[filterKey].to
              ? dateRangeFilters[filterKey].to
              : null,
        };
      } else if (rangeTo) {
        dateRangeFilters[filterKey] = {
          from:
            dateRangeFilters[filterKey] && dateRangeFilters[filterKey].from
              ? dateRangeFilters[filterKey].from
              : null,
          to: typeof value === "string" ? new Date(value) : null,
        };
      }
    }

    // New Date range types
    if (FilterType.NEW_DATE_RANGE === filter.type) {
      const itemKey = getNewDateRangeItemKey(queryKey);

      if (itemKey === "from") {
        dateRangeFilters[filterKey] = {
          from: typeof value === "string" ? new Date(value) : null,
          to:
            dateRangeFilters[filterKey] && dateRangeFilters[filterKey].to
              ? dateRangeFilters[filterKey].to
              : null,
        };
      } else if (itemKey === "to") {
        dateRangeFilters[filterKey] = {
          from:
            dateRangeFilters[filterKey] && dateRangeFilters[filterKey].from
              ? dateRangeFilters[filterKey].from
              : null,
          to: typeof value === "string" ? new Date(value) : null,
        };
      }
    }

    // Company logic
    if (filterKey === CompanySubtype.FREIGHT) {
      filters.additional_types.chosenOptions.push({
        name: "Freight",
        value: CompanySubtype.FREIGHT,
      });

      return;
    }

    if (filterKey === CompanySubtype.BUYER) {
      filters.additional_types.chosenOptions.push({
        name: "Buyer",
        value: CompanySubtype.BUYER,
      });

      return;
    }

    if (filterKey === CompanySubtype.SUPPLIER) {
      filters.additional_types.chosenOptions.push({
        name: "Supplier",
        value: CompanySubtype.SUPPLIER,
      });

      return;
    }

    if (filterKey === CompanySubtype.SERVICE_PROVIDER) {
      filters.additional_types.chosenOptions.push({
        name: "Service Provider",
        value: CompanySubtype.SERVICE_PROVIDER,
      });

      return;
    }

    // For boolean filters
    if (filter.type === FilterType.SWITCHER && "true" === value) {
      filter.chosenOptions = [
        {
          name: filter.title,
          value: value,
        },
      ];

      return;
    }

    // For 'taxonomies' filters (categories/tags)
    if (
      filterKey === "taxonomies" &&
      filters.hasOwnProperty("taxonomies_categories") &&
      filters.hasOwnProperty("taxonomies_tags")
    ) {
      const categoriesOptions = filters.taxonomies_categories.options.filter(
        (option) => value === option.value.toString()
      );
      const tagsOptions = filters.taxonomies_tags.options.filter(
        (option) => value === option.value.toString()
      );

      filters.taxonomies_categories.chosenOptions = categoriesOptions;
      filters.taxonomies_tags.chosenOptions = tagsOptions;
    }

    // For general filters
    if (filters.hasOwnProperty(filterKey)) {
      filter.chosenOptions = filter.options.filter((option) =>
        value.includes(option.value === null ? null : option.value.toString())
      );
    }
  });

  if (dateRangeFilters && 0 < Object.values(dateRangeFilters).length) {
    for (const [key, range] of Object.entries(dateRangeFilters)) {
      if (range.from && range.to) {
        const option: FilterOption = {
          name: `${filters[key].title}: ${useDateFormatLib(range.from, "dd/mm/yyyy")} - ${useDateFormatLib(range.to, "dd/mm/yyyy")}`,
          value: `${useDateFormatLib(range.from, "yyyy-mm-dd")}_${useDateFormatLib(range.to, "yyyy-mm-dd")}`,
        };

        filters[key].chosenOptions = [option];
      }
    }
  }

  return useGetFiltersParams(filters);
};
